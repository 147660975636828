import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
const TOKEN_HEADER_KEY = 'x-access-token';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authService: AuthService, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    const token = this.authService.getToken();
    let authReq = req;
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (authReq.url.includes("/refresh")) {
          this.authService.signOut();
          this.router.navigate(["auth/login"]);
        } else if (!authReq.url.includes('auth/login')) {
          return this.handle401Error(authReq, next);
        }
      }
      return throwError(error);
    }));
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.authService.getRefreshToken();
      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.authService.saveToken(token.body.accessToken);
            this.refreshTokenSubject.next(token.body.accessToken);
            sessionStorage.setItem("access_token", JSON.stringify(token.body.accessToken));
            sessionStorage.setItem("refresh_token", JSON.stringify(token.body.refreshToken));
            const authReqWithNewToken = this.addTokenHeader(request, token.body.data.accessToken);
            return next.handle(authReqWithNewToken);
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.signOut();
            this.router.navigate(["auth/login"]);
            return throwError(err);
          })
        );
      } else {
        this.isRefreshing = false;
        this.authService.signOut();
        this.router.navigate(["auth/login"]);
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((_token) => {
          const authReqWithNewToken = this.addTokenHeader(
            request,
            sessionStorage.getItem("access_token")
          );
          return next.handle(authReqWithNewToken);
        })
      );
    }
  }
  private addTokenHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        [TOKEN_HEADER_KEY]: token,
      },
    });
  }

}
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];