
import { Component, OnInit, Renderer2 } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { Event, NavigationStart, Router} from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {


  constructor(private router: Router, private titleService: Title,private analytics : AnalyticsService, private renderer: Renderer2) {}

  ngOnInit(): void {
    let currentEnv = this.router['location']._platformLocation.location.origin;
    if (currentEnv.startsWith("https://stage")) {
      this.titleService.setTitle('WorktualizeStage');
      
    } else {
      this.titleService.setTitle('Worktualize');
    }
    this.analytics.trackPageViews();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if(event.url === '/auth/login'){
          sessionStorage.clear();
        } else if(event.url === '/auth/download'){
          this.renderer.addClass(document.body, 'homelogin');
        } else if (event.url === '/auth/requestpass'){
          this.renderer.addClass(document.body, 'homelogin');
        }else {
          this.renderer.removeClass(document.body, 'homelogin');
        }
      }
  });
  }
}
