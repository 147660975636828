export const adminConstant = {
    date_time_format : "YYYY-MM-DD HH:mm:ss",
    default_day_start_time : " 00:00:00",
    default_day_end_time : " 23:59:59",
    date_format  : "YYYY-MM-DD",
    chart_short_date_format : "DD,MMM YY",
    chart_long_date_format : "DD,MMM YYYY",
    millisecondstoHours : "HH:mm",
    timeStampToTime : "hh:mm A",
    default_date_format : "DD/MM/YYYY",
    default_time_zone : "Asia/Kolkata",
    export_date_format : "DD-MM-YYYY",
}

export const toastrTitle = {
    error_title : "Error",
    warn_title : "Warning",
    success_title : "Success"
}

export const toastrMessage = {
    timesheet_upto_date : "Timesheet Up-To-Date",
    duration_less_than_min : "Duration Less than a min",
    timesheet_update_pending : "Timesheet update in ZOHO is pending",
    no_data : "No Data to Export",
    warning_message_user_hierarchy : "Please move the user to Available to Selected or Selected to Available.",
    success_message_user_hierarchy: "User Hierarchy Saved Successfully.",
    invalid_cred :"Invalid Credentials",
}